export default function all(state = {}, action) {
  switch (action.type) {
    case 'TOGGLE_MISC':
      return {
        ...state,
        [action.payload.key]: !state[action.payload.key],
      };
    case 'SET_VAL':
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    default:
      break;
  }
  return state;
}
