import React from 'react';
import PTs from 'prop-types';
import ga from 'react-ga';

import AppContextShape from '../lib/AppContextShape';
import appContextTypes from '../lib/appContextTypes';

class App extends React.PureComponent {
  static propTypes = {
    children: PTs.element.isRequired,
    context: AppContextShape.isRequired,
  };

  static childContextTypes = appContextTypes;

  getChildContext() {
    return this.props.context;
  }

  componentDidMount() {
    if (process.env.BROWSER) {
      ga.initialize('UA-78618414-1', { debug: false });
      ga.pageview(window.location.pathname + window.location.search);
    }
  }

  render() {
    return React.Children.only(this.props.children);
  }
}

export default App;
