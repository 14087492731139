import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import createLogger from './logger';
import createRootReducer from './reducers';

export default function configureStore(initialState, helpersConfig_unused) {
  const middleware = [thunk];

  let enhancer;

  if (__DEV__) {
    middleware.push(createLogger());

    // https://github.com/zalmoxisus/redux-devtools-extension#redux-devtools-extension
    let devToolsExtension = f => f;
    // eslint-disable-next-line no-underscore-dangle
    if (process.env.__CLIENT__ && window.devToolsExtension) {
      devToolsExtension = window.devToolsExtension();
    }

    enhancer = compose(applyMiddleware(...middleware), devToolsExtension);
  } else {
    enhancer = applyMiddleware(...middleware);
  }

  // Persist the state in local storage
  // const persistedState = loadState(initialState);

  const rootReducer = createRootReducer();

  // See https://github.com/rackt/redux/releases/tag/v3.1.0
  const store = createStore(rootReducer, initialState, enhancer);

  // Hot reload reducers (requires Webpack or Browserify HMR to be enabled)
  if (__DEV__ && module.hot) {
    module.hot.accept('./reducers', () =>
      // eslint-disable-next-line global-require
      store.replaceReducer(require('./reducers').default),
    );
  }

  return store;
}
