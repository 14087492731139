import PTs from 'prop-types';

import { Provider as ReduxProvider } from 'react-redux';

const appContextTypes = {
  // Enables critical path CSS rendering
  // https://github.com/kriasoft/isomorphic-style-loader
  insertCss: PTs.func.isRequired,
  locale: PTs.string,
  // Client location
  pathname: PTs.string.isRequired,
  query: PTs.shape({}),
  // Integrate Redux
  // http://redux.js.org/docs/basics/UsageWithReact.html
  ...ReduxProvider.childContextTypes,
};

export default appContextTypes;
