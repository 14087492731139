import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import all from './all';
import blogData from './blogData';

export default function createRootReducer() {
  const reducers = combineReducers({
    all,
    blogData,
    form: formReducer,
  });

  return reducers;
}
