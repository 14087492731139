// The top-level (parent) route.
// Note: This may also be an array of Route objects.

const routes = {
  path: '',

  async action({ next }) {
    // Execute each child route until one of them return the result
    const actionSpec = await next();

    // Provide default values for title, description etc.
    actionSpec.title = `${actionSpec.title || 'Untitled Page'}`;
    actionSpec.description = actionSpec.description || '';

    return actionSpec;
  },

  // Keep in mind, routes are evaluated in order
  children: [
    {
      path: '',
      load: () => import(/* webpackChunkName: "home" */ './home'),
    },
    {
      path: '/solutions',
      children: [
        {
          path: '',
          load: () => import(/* webpackChunkName: "solutions" */ './solutions'),
        },
        {
          path: '/consulting',
          load: () =>
            import(/* webpackChunkName: "consulting" */ './consulting'),
        },
        {
          path: '/game-development',
          load: () => import(/* webpackChunkName: "game-dev" */ './gameDev'),
        },
        {
          path: '/user-interface-user-experience',
          load: () =>
            import(/* webpackChunkName: "user-interface-user-experience" */ './userInterface'),
        },
        {
          path: '/seo',
          load: () => import(/* webpackChunkName: "seo" */ './seo'),
        },
        {
          path: '/web-design',
          load: () =>
            import(/* webpackChunkName: "web-design" */ './webDesign'),
        },
        {
          path: '/web-development',
          load: () => import(/* webpackChunkName: "web-dev" */ './webDev'),
        },
        {
          path: '/mobile-development',
          load: () =>
            import(/* webpackChunkName: "mobile-dev" */ './mobileDev'),
        },
        {
          path: '/app-development',
          load: () => import(/* webpackChunkName: "app-dev" */ './appDev'),
        },
      ],
    },

    {
      path: '/services',
      children: [
        {
          path: '',
        },
        {
          path: '/startup-web-development',
        },
        {
          path: '/enterprise-software-development',
        },
        {
          path: '/web-development',
          children: [
            {
              path: '',
            },
            {
              path: 'react',
            },
            {
              path: 'node',
            },
          ],
        },
      ],
    },

    {
      path: '/industries',
      children: [
        {
          path: '',
        },
        {
          path: '/education',
        },
      ],
    },

    {
      path: '/portfolio',
      children: [
        {
          path: '',
          load: () => import(/* webpackChunkName: "portfolio" */ './portfolio'),
        },
        {
          path: '/cookie-cruncher',
          load: () =>
            import(/* webpackChunkName: "cookie-cruncher" */ './cookieCruncher'),
        },
        {
          path: '/grayscale',
          load: () => import(/* webpackChunkName: "grayscale" */ './grayscale'),
        },
        {
          path: '/marc-marmel',
          load: () =>
            import(/* webpackChunkName: "marcMarmel" */ './marcMarmel'),
        },
        {
          path: '/sara',
          load: () => import(/* webpackChunkName: "sara" */ './sara'),
        },
        {
          path: '/inheritance-salvage',
          load: () =>
            import(
              /* webpackChunkName: "inheritance-salvage" */ './inheritanceSalvage'
            ),
        },
        {
          path: '/shadower',
          load: () => import(/* webpackChunkName: "shadower" */ './shadower'),
        },
        {
          path: '/revvy',
          load: () => import(/* webpackChunkName: "revvy" */ './revvy'),
        },
        {
          path: '/sun-wellness',
          load: () =>
            import(/* webpackChunkName: "sun-wellness" */ './sunWellness'),
        },
      ],
    },
    {
      path: '/team',
      load: () => import(/* webpackChunkName: "team" */ './team'),
    },
    {
      path: '/how-to-start',
      load: () => import(/* webpackChunkName: "how-to-start" */ './howToStart'),
    },
    {
      path: '/contact',
      load: () => import(/* webpackChunkName: "contact" */ './contact'),
    },

    // blog routes
    {
      path: '/blog',
      children: [
        {
          path: '',
          load: () => import(/* webpackChunkName: "blog" */ './blog'),
        },
        {
          path: '/ethereum-trust-fund',
          load: () =>
            import(
              /* webpackChunkName: "ethereum-trust-fund" */ './blogPosts/ethereumTrustFund'
            ),
        },
        {
          path: '/:blogSlug',
          load: () => import(/* webpackChunkName: "blog-post" */ './blogPost'),
        },
      ],
    },

    {
      path: '/terms-and-conditions',
      load: () => import(/* webpackChunkName: "terms" */ './terms'),
    },

    {
      path: '/full-sitemap',
      load: () =>
        import(/* webpackChunkName: "full-sitemap" */ './fullSitemap'),
    },

    {
      path: '/referral-badge',
      load: () =>
        import(/* webpackChunkName: "referral-badge" */ './referralBadge'),
    },

    // Wildcard routes, e.g. { path: '*', ... } (must go last)
    {
      path: '(.*)',
      load: () => import(/* webpackChunkName: "not-found" */ './notFound'),
    },
  ],
};

// The error page is available by permanent url for development mode
if (__DEV__) {
  routes.children.unshift({
    path: '/error',
    /* eslint-disable-next-line global-require */
    action: require('./error').default,
  });
}

export default routes;
